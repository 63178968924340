'use client';

import { ReactNode, forwardRef } from 'react';
import styled, { css } from 'styled-components';

type Alignment = 'center' | 'end' | 'space-around' | 'space-between' | 'start';

type MDSHStackProps = {
  alignment?: Alignment;
  children?: ReactNode;
  className?: string;
  spacing?: number;
  style?: React.CSSProperties;
  verticalAt?: 'tablet' | 'desktop';
};

type StyledProps = {
  $alignment: Alignment;
  $spacing: number;
  style?: React.CSSProperties;
  $verticalAt?: 'tablet' | 'desktop';
};

const verticalAtDesktop = css`
  flex-direction: column;
  ${(p) => p.theme.breakpoints.desktop} {
    flex-direction: row;
  }
`;

const verticalAtTablet = css`
  flex-direction: column;
  ${(p) => p.theme.breakpoints.tablet} {
    flex-direction: row;
  }
`;

const StyledHStack = styled.div<StyledProps>`
  display: flex;
  gap: ${({ $spacing }) => `${$spacing}rem`};
  justify-content: ${({ $alignment }) => $alignment};

  ${({ $verticalAt }) => $verticalAt === 'desktop' && verticalAtDesktop}
  ${({ $verticalAt }) => $verticalAt === 'tablet' && verticalAtTablet}
`;
/**
  ```tsx
    import { MDSHStack } from '@midwest/web/stacks';
  ```
 * Flex box for displaying items horizontally.
 */
export const MDSHStack = forwardRef<HTMLDivElement, MDSHStackProps>(
  (
    {
      alignment = 'start',
      className = '',
      spacing = 1,
      style = {},
      children,
      verticalAt,
    },
    ref,
  ) => {
    return (
      <StyledHStack
        ref={ref}
        $alignment={alignment}
        className={className}
        $spacing={spacing}
        style={style}
        $verticalAt={verticalAt}
      >
        {children}
      </StyledHStack>
    );
  },
);

MDSHStack.displayName = 'MDSHStack';
