'use client';

import { MDSText } from '@exploration/brandRefreshTheme';
import { MDSTextInput } from '@midwest/web/forms';
import { MDSHStack, MDSVStack } from '@midwest/web/stacks';
import { tablet } from '@thrivent/midwest-shared';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1rem;
  margin-top: unset;
`;

export const StyledContainer = styled.div`
  width: 38.5rem;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;

  gap: 1rem;

  ${tablet} {
    flex-direction: row;
    padding: 1.5rem;
  }
`;

export const SearchInput = styled(MDSTextInput)`
  width: 100%;

  label {
    visibility: hidden;
  }
`;

export const VStack = styled(MDSVStack)`
  width: 100%;
  gap: 0;
`;

export const InputLabel = styled(MDSText.TitleSm)`
  color: ${(p) => p.theme.midwestColors.identityBrandPrimaryHeavy};
  text-align: center;
  margin-bottom: -0.55rem;
`;

export const HStack = styled(MDSHStack)`
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 0;

  ${tablet} {
    gap: 1rem;
    flex-wrap: nowrap;
  }
`;
