'use client';

import { ReactNode, forwardRef } from 'react';
import styled from 'styled-components';

type Alignment = 'center' | 'end' | 'normal' | 'start';

type MDSVStackProps = {
  children?: ReactNode;
  className?: string;
  alignment?: Alignment;
  spacing?: number;
  style?: React.CSSProperties;
};

type StyledProps = {
  $alignment: Alignment;
  $spacing: number;
  style?: React.CSSProperties;
};

const StyledVStack = styled.div<StyledProps>`
  align-items: ${({ $alignment }) => $alignment};
  display: flex;
  gap: ${({ $spacing }) => `${$spacing}rem`};
  flex-direction: column;
`;
/**
  ```tsx
    import { MDSVStack } from '@midwest/web/stacks';
  ```
 * Flex box for displaying items vertically.
 */
export const MDSVStack = forwardRef<HTMLDivElement, MDSVStackProps>(
  (
    { alignment = 'normal', className = '', spacing = 1, style = {}, children },
    ref,
  ) => {
    return (
      <StyledVStack
        $alignment={alignment}
        className={className}
        ref={ref}
        $spacing={spacing}
        style={style}
      >
        {children}
      </StyledVStack>
    );
  },
);

MDSVStack.displayName = 'MDSVStack';
