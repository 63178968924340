'use client';

import { AnchorButton, Chevron } from './AnchorButton.styles';
import { useRouter } from 'next/navigation';

type Props = {
  id?: string;
  buttonOnly?: boolean;
  showChevron: boolean;
  advisorSearchText?: string;
  ctaButton: string;
};
export const AdvisorButton = ({
  id,
  buttonOnly = false,
  showChevron,
  advisorSearchText,
  ctaButton,
  ...rest
}: Props) => {
  const router = useRouter();

  const goToAdvisorPage = (url = '') => {
    let value = '';
    if (advisorSearchText) {
      value = `?qp=${advisorSearchText}`;
    }
    router.push(`${url}${value}`);
  };

  return (
    <AnchorButton
      role="link"
      href="https://local.thrivent.com/"
      data-analytics-id={id}
      buttonOnly={buttonOnly}
      showChevron={showChevron}
      onClick={() => goToAdvisorPage('https://local.thrivent.com/')}
      {...rest}
    >
      {ctaButton} {showChevron && <Chevron />}
    </AnchorButton>
  );
};
