'use client';

import { ArrowRightIcon } from '../svg';
import { MDSAnchorButton } from '@midwest/web/button';
import { tablet } from '@thrivent/midwest-shared';
import styled from 'styled-components';

interface ButtonProps {
  buttonOnly?: boolean;
  showChevron?: boolean;
}

export const Chevron = styled(ArrowRightIcon)`
  margin-left: -0.25rem;
  path {
    fill: ${(p) => p.theme.midwestColors.textPrimaryDarkBG};
  }
`;

export const AnchorButton = styled(MDSAnchorButton)<ButtonProps>`
  display: block;
  height: auto;
  margin-top: ${({ buttonOnly }) => (buttonOnly ? '0.5rem' : '0')};
  min-width: 100%;
  background-color: ${(p) =>
    p.theme.midwestColors
      .componentButtonColorBackgroundStrongDestructiveDefault};
  color: ${(p) => p.theme.midwestColors.textPrimaryDarkBG};
  ${tablet} {
    min-width: 9em;
  }

  ${({ showChevron }) =>
    showChevron &&
    `
  display: unset;
  min-width: unset;
  font-weight: 700;
  text-decoration: none;
  width: 100%;
  margin-top: 1rem;
  ${tablet} {
    margin-top: 1.5rem;
    width: fit-content;
  }

  div { 
    max-height: 1rem;
  }
`}
`;
